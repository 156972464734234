import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { WarningModalService } from './components/warning-modal/warning-modal.service';
import { WarningModalConfig } from './models/WarningModalConfig';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('warningModal') warningModal!: ElementRef;
  public modalShown$!: Observable<boolean>;  
  public modalMessage$!: Observable<WarningModalConfig>;
  public modalMessageConfig:WarningModalConfig = new WarningModalConfig()

  constructor(
    private warningModalService: WarningModalService,
    private modalService: NgbModal
  ){}

  ngOnInit() {
    this.modalShown$ = this.warningModalService.onToggle;
    this.modalMessage$ = this.warningModalService.onMessage;

    this.modalShown$.subscribe((data) => {
      if(data)
        this.modalService.open(this.warningModal, { centered: true })
      else
        this.modalService.dismissAll()
    })

    this.modalMessage$.subscribe((data) => {
      if(data)
        this.modalMessageConfig = data
     
    })
  }
}
