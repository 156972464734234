import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WarningModalConfig } from 'src/app/models/WarningModalConfig';


@Injectable({
  providedIn: 'root'
})
export class WarningModalService {

  private toggleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onToggle: Observable<boolean> = this.toggleSubject.asObservable();

  private messageSubject: BehaviorSubject<WarningModalConfig> = new BehaviorSubject<WarningModalConfig>({});
  public onMessage: Observable<WarningModalConfig> = this.messageSubject.asObservable();

  constructor() { }

  showModal(message:WarningModalConfig) {
    if(message)
      this.messageSubject.next(message);
    this.toggleSubject.next(true)
  }

  hideModal() {
    this.toggleSubject.next(false)
  }

} 
