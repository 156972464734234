import { Component, Input, OnInit } from '@angular/core';
import { WarningModalConfig } from 'src/app/models/WarningModalConfig';
import { WarningModalService } from './warning-modal.service';


@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {

  @Input() public message!: WarningModalConfig;

  constructor(
    private warningModalService: WarningModalService
  ) {}

  ngOnInit(): void {
  }

  close() {
    this.warningModalService.hideModal()
  }

}
