import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    public loadingService: LoadingService,
    private changeDetector : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  
}
