import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from './components/loading/loading.module';
import { WarningModalModule } from './components/warning-modal/warning-modal.module';
import { WarningModalService } from './components/warning-modal/warning-modal.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    LoadingModule,
    WarningModalModule
  ],
  providers: [
    WarningModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
